body {
  background-color: #343a40;
  color: #ffffff;
}

.navbar {
  background-color: #343a40;
}

.card {
  background-color: #454d55;
  color: #ffffff;
}
